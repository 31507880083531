<template>
  <div class="orden mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Crear orden
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-row dense>
                          <v-col cols="12" md="6" sm="6">
                            <v-select
                              :items="stocks"
                              v-model="stock"
                              outlined
                              rounded
                              dense
                              label="Tipo de inventario"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-select
                              :items="proveedores"
                              outlined
                              rounded
                              dense
                              label="Proveedor"
                              item-text="proveedor"
                              v-model="proveedor"
                              @change="loadProductos()"
                              return-object
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-menu
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  outlined
                                  rounded
                                  dense
                                  label="Fecha"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                scrollable
                                v-model="date"
                                locale="es-co"
                                color="primary"
                                first-day-of-week="1"
                                @input="menu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-menu
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="vencimiento"
                                  outlined
                                  rounded
                                  dense
                                  label="Fecha vencimiento"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                scrollable
                                v-model="vencimiento"
                                locale="es-co"
                                color="primary"
                                first-day-of-week="1"
                                @input="menu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <strong>Forma de pago</strong>
                            <v-radio-group row v-model.number="forma_pago">
                              <v-radio label="Contado" :value="1"></v-radio>
                              <v-radio label="Credito" :value="2"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <p>
                          <strong>
                            Proveedor:
                          </strong>
                          {{ proveedor.proveedor }}
                          <br />
                          <strong>Nit: </strong>
                          {{ proveedor.nit }}
                          <br />
                          <strong>Dirección: </strong>
                          {{ proveedor.direccion }}
                          <br />
                          <strong>Teléfono: </strong>
                          {{ proveedor.telefono }}
                          <br />
                          <strong>Celular: </strong>
                          {{ proveedor.celular }}
                          <br />
                          <strong>Regimen: </strong>
                          {{ proveedor.regimen }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :search="search"
                  :headers="headers"
                  :footer-props="{
                    itemsPerPageText: 'Productos',
                    itemsPerPageOptions: [10, 25, 50, -1],
                  }"
                  :options="{
                    itemsPerPage: -1,
                  }"
                  :loading="loading_t"
                  :items="lista"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-col cols="4" class="px-0">
                        <v-text-field
                          outlined
                          rounded
                          dense
                          append-icon="mdi-magnify"
                          label="Buscar"
                          class="mt-6"
                          v-model="search"
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-btn
                        :dark="!loading"
                        :color="!loading ? 'black' : 'gray'"
                        :loading="loading"
                        :disabled="loading"
                        @click="guardar()"
                      >
                        <v-icon left>mdi-content-save</v-icon>
                        Guardar
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.cantidad="{ item }">
                    <v-text-field
                      style="width: 150px"
                      dense
                      outlined
                      rounded
                      class="mt-2"
                      :min="0"
                      type="number"
                      v-model.number="item.cantidad"
                      @change="calculo(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.valor="{ item }">
                    <v-text-field
                      style="width: 150px"
                      dense
                      outlined
                      rounded
                      class="mt-2"
                      :min="0"
                      type="number"
                      v-model.number="item.valor"
                      @change="calculo(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.iva="{ item }">
                    {{ item.iva }}%
                  </template>
                  <template v-slot:item.subtotal="{ item }">
                    {{ item.subtotal | currency }}
                  </template>
                  <template v-slot:item.total_iva="{ item }">
                    {{ item.total_iva | currency }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>Totalización</v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>Subtotal</th>
                            <td>
                              {{ totalizacion.subtotal | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>IVA</th>
                            <td>
                              {{ totalizacion.iva | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>
                              {{ totalizacion.total | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="8" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>Observación</v-card-title>
                  <v-card-subtitle
                    >Solo se permiten un maximo de 900
                    caracteres</v-card-subtitle
                  >
                  <v-card-text>
                    <v-textarea
                      rows="2"
                      outlined
                      counter
                      v-model="observacion"
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "orden",
  data: () => ({
    loading_t: false,
    loading: false,
    date: moment().format("YYYY-MM-DD"),
    vencimiento: moment().format("YYYY-MM-DD"),
    stock: 1,
    stocks: [
      { text: "Stock de venta", value: 1 },
      { text: "Stock de consumo", value: 2 },
    ],
    search: "",
    headers: [
      {
        text: "Codigo",
        value: "codigo",
        align: "start",
      },
      {
        text: "Producto",
        value: "producto",
      },
      {
        text: "Cantidad",
        value: "cantidad",
        sort: false,
      },
      {
        text: "Precio Uni",
        value: "valor",
        sort: false,
      },
      {
        text: "IVA",
        value: "iva",
      },
      {
        text: "Subtotal",
        value: "subtotal",
      },
      {
        text: "Total IVA",
        value: "total_iva",
      },
      {
        text: "Total",
        value: "total",
      },
    ],
    forma_pago: 1,
    proveedores: [],
    proveedor: {},
    productos: [],
    observacion: "",
  }),
  methods: {
    loadProveedores() {
      const body = {
        route: "/proveedores",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.proveedores = response.data.data;
        }
      });
    },
    loadProductos() {
      this.loading_t = true;
      const body = {
        route: "/productos_proveedor",
        params: {
          id: this.proveedor.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.productos = response.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    calculo(item) {
      item.valor = item.valor == "" ? 0 : item.valor;
      item.cantidad = item.cantidad == "" ? 0 : item.cantidad;
      let iva = Math.round(
        (((item.valor / ((100 + item.iva) / 100)) * item.iva) / 100) *
          item.cantidad
      );
      let subtotal = Math.round(
        (item.valor / ((100 + item.iva) / 100)) * item.cantidad
      );

      item.total_iva = iva;
      item.subtotal = subtotal;
      item.total = Math.round(subtotal + iva);
    },
    guardar() {
      const productos = this.lista.filter((p) => {
        return p.cantidad != 0;
      });
      if (productos.length > 0) {
        this.loading = true;

        let entradas = [];

        productos.forEach((p) => {
          entradas.push({
            producto_id: p.id,
            producto: p.producto,
            codigo: p.codigo,
            cantidad: p.cantidad,
            iva: p.iva,
            precio: p.valor,
            subtotal: p.subtotal,
            total_iva: p.total_iva,
            total: p.total,
          });
        });
        const body = {
          route: "/create_orden",
          data: {
            orden: {
              proveedor_id: this.proveedor.id,
              fecha: this.date,
              vencimiento: this.vencimiento,
              metodo: this.forma_pago,
              stock: this.stock,
              subtotal: this.totalizacion.subtotal,
              iva: this.totalizacion.iva,
              total: this.totalizacion.total,
              proveedor: this.proveedor.proveedor,
              nit_proveedor: this.proveedor.nit,
              telefono: this.proveedor.telefono,
              direccion: this.proveedor.direccion,
              observacion: this.observacion == "" ? null : this.observacion,
            },
            entradas,
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              this.$router.push("/detalleOrden/" + response.data.data.id);
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  created() {
    this.loadProveedores();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    stock_productos() {
      return this.productos.filter((producto) => {
        return parseInt(producto.inventario) == parseInt(this.stock);
      });
    },
    lista() {
      return this.stock_productos.map((p) => {
        let iva = 0;
        if (this.proveedor.regimen != "No Responsable de IVA") {
          iva = parseFloat(p.iva_compra);
        }
        let data = {
          id: p.id_activo,
          producto: p.producto,
          codigo: p.codigo,
          cantidad: 0,
          valor: parseFloat(p.compra),
          iva: iva,
          subtotal: 0,
          total_iva: 0,
          total: 0,
        };

        return data;
      });
    },

    totalizacion() {
      const productos = this.lista.filter((p) => {
        return p.cantidad != 0;
      });
      return {
        subtotal: productos.reduce((index, item) => {
          return index + item.subtotal;
        }, 0),
        iva: productos.reduce((index, item) => {
          return index + item.total_iva;
        }, 0),
        total: productos.reduce((index, item) => {
          return index + item.total;
        }, 0),
      };
    },
  },
  watch: {
    date(val) {
      if (this.forma_pago == 2) {
        this.vencimiento = moment(val, "YYYY-MM-DD")
          .add(1, "month")
          .format("YYYY-MM-DD");
      } else {
        this.vencimiento = this.date;
      }
      return this.date;
    },
    forma_pago(val) {
      if (val == 2) {
        this.vencimiento = moment(this.date, "YYYY-MM-DD")
          .add(1, "month")
          .format("YYYY-MM-DD");
      } else {
        this.vencimiento = this.date;
      }
      return this.forma_pago;
    },
  },
};
</script>
