var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orden mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Crear orden ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.stocks,"outlined":"","rounded":"","dense":"","label":"Tipo de inventario"},model:{value:(_vm.stock),callback:function ($$v) {_vm.stock=$$v},expression:"stock"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.proveedores,"outlined":"","rounded":"","dense":"","label":"Proveedor","item-text":"proveedor","return-object":""},on:{"change":function($event){return _vm.loadProductos()}},model:{value:(_vm.proveedor),callback:function ($$v) {_vm.proveedor=$$v},expression:"proveedor"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha vencimiento","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.vencimiento),callback:function ($$v) {_vm.vencimiento=$$v},expression:"vencimiento"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.vencimiento),callback:function ($$v) {_vm.vencimiento=$$v},expression:"vencimiento"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('strong',[_vm._v("Forma de pago")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.forma_pago),callback:function ($$v) {_vm.forma_pago=_vm._n($$v)},expression:"forma_pago"}},[_c('v-radio',{attrs:{"label":"Contado","value":1}}),_c('v-radio',{attrs:{"label":"Credito","value":2}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('p',[_c('strong',[_vm._v(" Proveedor: ")]),_vm._v(" "+_vm._s(_vm.proveedor.proveedor)+" "),_c('br'),_c('strong',[_vm._v("Nit: ")]),_vm._v(" "+_vm._s(_vm.proveedor.nit)+" "),_c('br'),_c('strong',[_vm._v("Dirección: ")]),_vm._v(" "+_vm._s(_vm.proveedor.direccion)+" "),_c('br'),_c('strong',[_vm._v("Teléfono: ")]),_vm._v(" "+_vm._s(_vm.proveedor.telefono)+" "),_c('br'),_c('strong',[_vm._v("Celular: ")]),_vm._v(" "+_vm._s(_vm.proveedor.celular)+" "),_c('br'),_c('strong',[_vm._v("Regimen: ")]),_vm._v(" "+_vm._s(_vm.proveedor.regimen)+" ")])])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"footer-props":{
                  itemsPerPageText: 'Productos',
                  itemsPerPageOptions: [10, 25, 50, -1],
                },"options":{
                  itemsPerPage: -1,
                },"loading":_vm.loading_t,"items":_vm.lista},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":!_vm.loading,"color":!_vm.loading ? 'black' : 'gray',"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.guardar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Guardar ")],1)],1)]},proxy:true},{key:"item.cantidad",fn:function(ref){
                var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-2",staticStyle:{"width":"150px"},attrs:{"dense":"","outlined":"","rounded":"","min":0,"type":"number"},on:{"change":function($event){return _vm.calculo(item)}},model:{value:(item.cantidad),callback:function ($$v) {_vm.$set(item, "cantidad", _vm._n($$v))},expression:"item.cantidad"}})]}},{key:"item.valor",fn:function(ref){
                var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-2",staticStyle:{"width":"150px"},attrs:{"dense":"","outlined":"","rounded":"","min":0,"type":"number"},on:{"change":function($event){return _vm.calculo(item)}},model:{value:(item.valor),callback:function ($$v) {_vm.$set(item, "valor", _vm._n($$v))},expression:"item.valor"}})]}},{key:"item.iva",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.iva)+"% ")]}},{key:"item.subtotal",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.subtotal))+" ")]}},{key:"item.total_iva",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total_iva))+" ")]}},{key:"item.total",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v("Totalización")]),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v("Subtotal")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalizacion.subtotal))+" ")])]),_c('tr',[_c('th',[_vm._v("IVA")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalizacion.iva))+" ")])]),_c('tr',[_c('th',[_vm._v("Total")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalizacion.total))+" ")])])])]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8","sm":"6"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v("Observación")]),_c('v-card-subtitle',[_vm._v("Solo se permiten un maximo de 900 caracteres")]),_c('v-card-text',[_c('v-textarea',{attrs:{"rows":"2","outlined":"","counter":""},model:{value:(_vm.observacion),callback:function ($$v) {_vm.observacion=$$v},expression:"observacion"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }